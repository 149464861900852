import Vue from 'vue'
import store from '@/store'
import VueRouter from 'vue-router'
import permissions from '../permissions'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return {x: 0, y: 0}
    },
    routes: [
        {
            path: '/',
            name: 'dashboard',
            component: () => import('@/views/Dashboard.vue')
        },
        {
            path: '/map',
            name: 'map',
            component: () => import('@/views/MapView/MapView')
        },
        {
            // API returns only outlets that user has permission to see
            path: '/outlets',
            name: 'outlets',
            component: () => import('@/views/Outlet/Outlets')
        },
        {
            path: '/outlet/add',
            name: 'outlet/add',
            component: () => import('@/views/Outlet/AddOutletPage'),
            meta: {requiredPermission: permissions.AddOutlet}
        },
        {
            path: '/outlet/importFromExcel',
            name: 'outlet-import-from-excel',
            component: () => import('@/views/Outlet/ImportOutletsFromExcelPage')
        },
        {
            path: '/outlet/:id',
            name: 'outlet',
            component: () => import('@/views/Outlet/Outlet')
        },
        {
            path: '/outlet_segments',
            name: 'outlet_segments',
            component: () => import('@/views/OutletSegment/OutletSegments')
        },
        {
            path: '/products',
            name: 'products',
            component: () => import('@/views/Product/Products')
        },
        {
            path: '/warehouse_products',
            name: 'warehouse_products',
            component: () => import('@/views/WarehouseProduct/WarehouseProducts')
        },
        {
            path: '/product_categories',
            name: 'product_categories',
            component: () => import('@/views/ProductCategory/ProductCategories')
        },
        {
            path: '/areas',
            name: 'areas',
            component: () => import('@/views/Area/Areas'),
            meta: {requiredPermission: permissions.ViewAllAreas}
        },
        {
            path: '/area/add',
            name: 'add-area',
            component: () => import('@/views/Area/AddArea'),
            meta: {requiredPermission: permissions.AddAreas}
        },
        {
            path: '/area/:id/edit',
            name: 'edit-area',
            component: () => import('@/views/Area/EditArea'),
            meta: {requiredPermission: permissions.EditAreas}
        },
        {
            path: '/territories',
            name: 'territories',
            component: () => import('@/views/Territory/Territories')
        },
        {
            path: '/districts',
            name: 'districts',
            component: () => import('@/views/District/Districts')
        },
        {
            path: '/warehouses',
            name: 'warehouses',
            component: () => import('@/views/Warehouse/Warehouses'),
            meta: {requiredPermission: permissions.ViewWarehouses}
        },
        {
            path: '/warehouse/add',
            name: 'warehouse/add',
            component: () => import('@/views/Warehouse/AddWarehousePage'),
            meta: {requiredPermission: permissions.AddWarehouses}
        },
        {
            path: '/warehouse/:id/edit',
            name: 'warehouse/edit',
            component: () => import('@/views/Warehouse/EditWarehousePage'),
            meta: {requiredPermission: permissions.EditWarehouses}
        },
        {
            path: '/sales',
            name: 'sales',
            component: () => import('@/views/Sale/Sales')
        },
        {
            path: '/distribution_vehicles',
            name: 'distribution_vehicles',
            component: () => import('@/views/DistributionVehicle/DistributionVehicles'),
            meta: {
                needsVehiclePermission: true,
                requiredPermission: permissions.ViewDistributionVehicles
            }
        },
        {
            path: '/distribution_vehicle/:id',
            name: 'distribution_vehicle',
            component: () => import('@/views/DistributionVehicle/DistributionVehicle')
        },
        {
            // everyone can see this route
            path: '/promotional_items',
            name: 'promotional_items',
            component: () => import('@/views/PromotionalItem/PromotionalItems')
        },
        {
            path: '/promotional_item/:id',
            name: 'promotional_item',
            component: () => import('@/views/PromotionalItem/PromotionalItem')
        },
        {
            path: '/promotional_item/importFromExcel',
            name: 'promotional_item-import-from-excel',
            component: () => import('@/views/PromotionalItem/ImportPromotionalItemsFromExcel')
        },
        {
            path: '/promotional_item_types',
            name: 'promotional_item_types',
            component: () => import('@/views/PromotionalItem/PromotionalItemTypes')
        },
        {
            path: '/config/default_map_view',
            name: 'config/default_map_view',
            component: () => import('@/views/Config/DefaultMapViewConfig')
        },
        {
            path: '/config/general_config',
            name: 'config/general_config',
            component: () => import('@/views/Config/GeneralConfig')
        },
        {
            // everyone can see this route
            path: '/surveys',
            name: 'surveys',
            component: () => import('@/views/Survey/Surveys')
        },
        {
            // everyone can see this route
            path: '/survey/add',
            name: 'add-survey',
            component: () => import('@/views/Survey/AddSurveyPage')
        },
        {
            path: '/survey/:id',
            name: 'survey',
            component: () => import('@/views/Survey/Survey')
        },
        {
            path: '/user_requests',
            name: 'user_requests',
            component: () => import('@/views/UserRequest/UserRequests')
        },
        {
            path: '/users',
            name: 'users',
            meta: {requiredPermission: permissions.ManageUsers},
            component: () => import('@/views/User/Users')
        },
        {
            path: '/user/:id',
            name: 'user',
            component: () => import('@/views/User/User')
        },
        {
            path: '/user/survey_results/:id',
            name: 'userResults',
            component: () => import('@/views/User/UserSurveyResults')
        },
        {
            path: '/roles',
            name: 'roles',
            component: () => import('@/views/Role/Roles'),
            meta: {requiredPermission: permissions.ManageRoles}
        },
        {
            path: '/login',
            name: 'login',
            component: () => import('@/views/Login.vue'),
            meta: {
                layout: 'full'
            }
        },
        {
            path: '/error-404',
            name: 'error-404',
            component: () => import('@/views/error/Error404.vue'),
            meta: {
                layout: 'full'
            }
        },
        {
            path: '*',
            redirect: 'error-404'
        }
    ]
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = 'none'
    }
})

router.beforeEach((to, from, next) => {
    if (!store.getters['app/loggedIn'] && to.path !== '/login') {
        return next('/login')
    } else if (store.getters['app/loggedIn'] && to.path === '/login') {
        return next('/')
    }

    if (to.meta.requiredPermission && !store.state.app.permissions.permissions.includes(to.meta.requiredPermission)) {

        // for distribution vehicle route we need either ViewDistributionVehicles or has_vehicle
        if (to.meta.needsVehiclePermission && store.state.app.permissions.has_vehicle)  {
            return next()
        } 
        
        return next('/')
        
    }

  
    next()
})

export default router
