import { $themeBreakpoints } from '@themeConfig'
import axios from 'axios'

export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    shallShowOverlay: false,

    userData: {},
    loggedIn: false,
    default_map_view: {},
    permissions: {
      permissions: [],
      has_vehicle: false,
      warehouses: [],
      areas: [],
      territories: [],
      districts: [],
      outlets: []
    }
  },
  getters: {
    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
    userData: state => {
      return state.userData
    },
    loggedIn: state => {
      return state.loggedIn
    }
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
    SET_DEFAULT_MAP_VIEW(state, val) {
      if (val && val.zoom !== undefined && val.center !== undefined) {
        state.default_map_view = val
      } else {
        state.default_map_view = {center: [0, 0], zoom: 1}
      }
    },
    UPDATE_USER_DATA(state, data) {
      state.userData = data
    },
    SET_LOGIN_STATUS(state, status) {
      state.loggedIn = status
    },
    SET_USER_PERMISSIONS(state, permissions) {
      state.permissions = permissions
    }
  },
  actions: {
    updateDefaultMapView({ commit }) {
      axios.get('/api/management/v1/config/default_map_view').then(function(response) {
        commit('SET_DEFAULT_MAP_VIEW', response.data)
      })
    },
    updateUserData({ commit }, data) {
      commit('UPDATE_USER_DATA', data)
    },
    setLoginStatus({ commit }, data) {
      commit('SET_LOGIN_STATUS', data)
    },
    updateUserPermissions({ commit }) {
      axios.get('/api/management/v1/permissions').then(function(response) {
        commit('SET_USER_PERMISSIONS', response.data)
      })
    }
  }
}
