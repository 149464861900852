const permissions = {
    ManageUsers : 101,
    ManageRoles : 102,
    AddUsers    : 103,
    EditUsers   : 104,
    RemoveUsers : 105,

    ViewAllOutlets     : 201,
    AddOutlet          : 202,
    EditOutlets        : 203,
    ApproveOutlet      : 204,
    AddOutletInAnyArea : 205,
    RemoveOutlet       : 206,
    DeactivateOutlet   : 207,
    AddSegments        : 210,
    EditSegments       : 211,
    RemoveSegments     : 212,

    ViewAllAreas : 301,
    AddAreas     : 302,
    EditAreas    : 303,
    RemoveAreas  : 304,

    AddProducts    : 402,
    EditProducts   : 403,
    RemoveProducts : 404,

    ViewAllPromotionalItems : 501,
    AddPromotionalItems     : 502,
    EditPromotionalItems    : 503,
    AssignPromotionalItems  : 504,
    RemovePromotionalItems  : 505,

    ViewWarehouses   : 601,
    AddWarehouses    : 602,
    EditWarehouses   : 603,
    RemoveWarehouses : 604,

    ManageSystemConfig : 703,

    ViewDistributionVehicles        : 801,
    EditDistributionVehicles        : 802,
    EditDistributionVehicleLoads    : 803,
    AddDistributionVehicle          : 804,
    RemoveDistributionVehicle       : 805,
    EditAllDistributionVehicleLoads : 806,

    AddUserRequests    : 901,
    CloseUserRequests  : 902,
    RemoveUserRequests : 903,
    ViewAllUserRequests : 904,

    EditSales                 : 1001,
    ViewAllSales              : 1002,
    ViewSalesForAssignedAreas : 1003,
    RemoveSales               : 1004,

    ViewSurveys   : 1101,
    EditSurveys   : 1102,
    AddSurveys    : 1103,
    RemoveSurveys : 1104,
    RemoveSurveyResults : 1105
}

export default permissions